import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UsersService from "../services/UsersService";
import validator from "validator";

import {
  Button,
  Card,
  MaskedTextInput,
  Page,
  PasswordInput,
  useViewModel,
  useValidationModel,
} from "@ediframework/ui.components";

const ResetPassword = (props) => {
  const { t } = useTranslation(["ResetPassword", "Shared"]);

  const [btnLoading, setBtnLoading] = useState(false);

  const prevState = props.location.state;
  const { viewModel, updateViewModel } = useViewModel({
    Email: prevState.Email,
    Code: "",
    NewPassword: "",
    ConfirmPassword: "",
    ResettingPassword: true,
  });

   const { validationModel, addValidation, clearValidations } =
     useValidationModel();

  const handleSubmit = () => {
    clearValidations();

    const codeIsEmpty = validator.isEmpty(viewModel.Code);
    const newPasswordIsEmpty = validator.isEmpty(viewModel.NewPassword);
    const confirmPasswordIsEmpty = validator.isEmpty(viewModel.ConfirmPassword);

    if (codeIsEmpty) addValidation("Code", t("Shared:FieldRequired"));

    if (newPasswordIsEmpty)
      addValidation("NewPassword", t("Shared:FieldRequired"));

    if (confirmPasswordIsEmpty)
      addValidation("ConfirmPassword", t("Shared:FieldRequired"));

    if (!codeIsEmpty && !newPasswordIsEmpty && !confirmPasswordIsEmpty) {
      setBtnLoading(true);

      const { updateUser } = UsersService();

      updateUser(viewModel)
        .then((response) => {
          if (response.data.Success) {
            props.history.push("ResetPasswordConfirmation");
          } else {
            setBtnLoading(false);
            addValidation("Code", t("CodeError"));
          }
        })
        .catch((error) => {
          console.error(error);
          setBtnLoading(false);
          addValidation("Code", t("CodeError"));
        });
    } else {
      setBtnLoading(false);
    }
  };

  const handleCancel = () => {
    window.location.href = import.meta.env.VITE_MAIN_URL;
  };

  useEffect(() => {
    if (viewModel.ConfirmPassword !== viewModel.NewPassword)
      addValidation("ConfirmPassword", t("ConfirmPwError"));
  }, [viewModel.ConfirmPassword, viewModel.NewPassword]);

  return (
    <Page centered showBg className="align-items-center">
      <Card>
        <Card.Body>
          <Card.Title className="fw-bold text-center pb-4">
            {t("ResetPassword")}
          </Card.Title>
          <Card.Text>{t("Description")}</Card.Text>
          <Card.Text>
            <MaskedTextInput
              id="Code"
              name="Code"
              label={t("ConfirmationCode")}
              onChange={updateViewModel}
              disabled={btnLoading}
              validationModel={validationModel}
              value={viewModel.Code}
              mask="000000"
              type="tel"
            />
          </Card.Text>
          <Card.Text>
            <PasswordInput
              label={t("Shared:Password")}
              id="NewPassword"
              name="NewPassword"
              value={viewModel.NewPassword}
              validationModel={validationModel}
              onChange={updateViewModel}
              disabled={btnLoading}
            />
          </Card.Text>
          <Card.Text className="pb-2">
            <PasswordInput
              label={t("ConfirmNewPassword")}
              id="ConfirmPassword"
              name="ConfirmPassword"
              value={viewModel.ConfirmPassword}
              validationModel={validationModel}
              onChange={updateViewModel}
              disabled={btnLoading}
            />
          </Card.Text>
          <Button
            id="resetPassword"
            type="primary"
            loading={btnLoading}
            btnBlock="true"
            onClick={handleSubmit}
          >
            {t("ResetPassword")}
          </Button>

          <div className="text-center pt-3">
            <Button
              id="cancelBtn"
              type="light"
              btnBlock="true"
              onClick={handleCancel}
              disabled={btnLoading}
            >
              {t("Shared:Cancel")}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default ResetPassword;
