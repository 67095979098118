import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import validator from "validator";
import ConfirmationCodeService from "../services/ConfirmationCodeService";

import {
  Page,
  TextInput,
  Card,
  Button,
  useViewModel,
  useValidationModel,
} from "@ediframework/ui.components";

const ForgotPassword = (props) => {
  const { t } = useTranslation(["ForgotPassword", "Shared"]);

  const [btnLoading, setBtnLoading] = useState(false);

  const { viewModel, updateViewModel } = useViewModel({
    Email: "",
  });

   const { validationModel, addValidation, clearValidations } =
     useValidationModel();

  const handleSubmit = () => {
    clearValidations();

    if (validator.isEmail(viewModel.Email)) {
      setBtnLoading(true);

      const { postConfCode } = ConfirmationCodeService();

      postConfCode(viewModel)
        .then(() => {
          props.history.push("ResetPassword", viewModel);
        })
        .catch((error) => console.error(error));
    } else {
      addValidation("Email", t("EmailError"));
    }
  };

  const handleCancel = () => {
    window.location.href = import.meta.env.VITE_MAIN_URL;
  };

  return (
    <Page centered showBg className="align-items-center">
      <Card>
        <Card.Body>
          <Card.Title className="fw-bold text-center pb-4">
            {t("Title")}
          </Card.Title>
          <Card.Text>{t("Description")}</Card.Text>
          <Card.Text>
            <TextInput
              id="Email"
              name="Email"
              label={t("Shared:Email")}
              maxLength="100"
              value={viewModel.Email}
              validationModel={validationModel}
              onChange={updateViewModel}
              onEnter={handleSubmit}
              disabled={btnLoading}
            />
          </Card.Text>
          <Button
            id="submitBtn"
            type="primary"
            btnBlock="true"
            loading={btnLoading}
            onClick={handleSubmit}
          >
            {t("SendInstructions")}
          </Button>
          <div className="text-center pt-3">
            <Button
              id="cancelBtn"
              type="light"
              btnBlock="true"
              onClick={handleCancel}
              disabled={btnLoading}
            >
              {t("Shared:Cancel")}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default ForgotPassword;
