import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Enums from "../types/Enums";
import validator from "validator";

import AccountService from "../services/AccountService";
import AccountInvitationService from "../services/AccountInvitationService";
import UserInvitationsService from "../services/UserInvitationsService";
import UsersService from "../services/UsersService";

import {
  Page,
  TextInput,
  Card,
  PasswordInput,
  Button,
  AnchorLink,
  Notification,
  useQueryParams,
  useReCAPTCHAv3,
  useViewModel,
  useValidationModel,
} from "@ediframework/ui.components";

const CreateAccount = (props) => {
  const { t } = useTranslation(["CreateAccount", "Shared"]);
  const [btnLoading, setBtnLoading] = useState(false);
  const { viewModel, updateViewModel, updateAllViewModel } = useViewModel({
    Email: "",
    CompanyName: "",
    CompanyIndustry: "",
    CompanySize: "",
    EnvironmentDisplayName: "",
    EnvironmentUrlName: "",
    Status: 0,
    FirstName: "",
    LastName: "",
    Password: "",
    ConfirmPassword: "",
    HasExpired: false,
    IsAdminUser: false,
    UserRole: null,
  });

  const { validationModel, addValidation, clearValidations } =
    useValidationModel();

  const queryParams = useQueryParams();
  const invitationId = queryParams.get("id");
  const invitationType = queryParams.get("type");

  const getToken = useReCAPTCHAv3(import.meta.env.VITE_RECAPTCHA_KEY);

  const handleLogin = () => {
    window.location.href = import.meta.env.VITE_MAIN_URL;
  };

  const handleSubmit = () => {
    if (validate()) {
      setBtnLoading(true);
      getToken()
        .then((token) => {
          if (invitationType && invitationType === "Account") {
            const { patchInvite } = AccountInvitationService();
            const { postAccount } = AccountService();

            let request = {
              Name: viewModel.CompanyName,
              EnvironmentDisplayName: viewModel.EnvironmentDisplayName,
              EnvironmentUrlName: viewModel.EnvironmentUrlName,
              Industry: viewModel.CompanyIndustry,
              Size: viewModel.CompanySize,
              UserLicenses: viewModel.UserLicenses,
              PrimaryOwnerEmail: viewModel.Email,
              Password: viewModel.Password,
              FirstName: viewModel.FirstName,
              LastName: viewModel.LastName,
              Token: token,
            };

            postAccount(request)
              .then((response) => {
                if (response && response.data.Id) {
                  patchInvite({
                    Id: invitationId,
                    Status: Enums.InvitationStatus.Accepted,
                  }).then(() => {
                    window.location.href = import.meta.env.VITE_MAIN_URL;
                  });
                }
              })
              .catch(() => {
                setBtnLoading(false);
              });
          } else {
            const { patchUserInvitation } = UserInvitationsService();
            const { postUser } = UsersService();

            const userRoles = [viewModel.UserRole];

            if (viewModel.IsAdminUser) userRoles.push(Enums.Roles.Admin);

            const request = {
              Email: viewModel.Email,
              Password: viewModel.Password,
              FirstName: viewModel.FirstName,
              LastName: viewModel.LastName,
              AccountId: viewModel.AccountId,
              Roles: userRoles,
              Token: token,
            };

            postUser(request)
              .then((response) => {
                if (response && response.data.Id) {
                  patchUserInvitation({
                    Id: invitationId,
                    Status: Enums.InvitationStatus.Accepted,
                  }).then(() => {
                    window.location.href = import.meta.env.VITE_MAIN_URL;
                  });
                }
              })
              .catch(() => {
                setBtnLoading(false);
              });
          }
        })
        .catch((e) => {
          console.error(e);
          Notification.error("An unexpected error has ocurred");
          setBtnLoading(false);
        });
    } else {
      setBtnLoading(false);
    }
  };

  const validate = () => {
    clearValidations();

    let result = true;

    if (validator.isEmpty(viewModel.FirstName)) {
      addValidation("FirstName", t("Shared:FieldRequired"));
      result = false;
    }

    if (validator.isEmpty(viewModel.LastName)) {
      addValidation("LastName", t("Shared:FieldRequired"));
      result = false;
    }

    if (
      validator.isEmpty(viewModel.Password) ||
      validator.isEmpty(viewModel.ConfirmPassword) ||
      viewModel.ConfirmPassword !== viewModel.Password
    ) {
      addValidation("ConfirmPassword", t("ConfirmPwError"));
      result = false;
    }

    return result;
  };

  useEffect(() => {
    if (invitationId) {
      if (invitationType && invitationType === "Account") {
        const { getInvite } = AccountInvitationService();

        getInvite(invitationId).then((response) => {
          updateAllViewModel({
            CompanyName: response.data.Name,
            Email: response.data.PrimaryOwnerEmail,
            EnvironmentDisplayName: response.data.EnvironmentDisplayName,
            EnvironmentUrlName: response.data.EnvironmentUrlName,
            CompanyIndustry: response.data.CompanyIndustry,
            CompanySize: response.data.CompanySize,
            UserLicenses: response.data.UserLicenses,
            Status: response.data.Status,
            HasExpired: response.data.HasExpired,
            FirstName: "",
            LastName: "",
            Password: "",
            ConfirmPassword: "",
          });
        });
      } else {
        const { getUserInvitation } = UserInvitationsService();

        getUserInvitation(invitationId).then((response) => {
          updateAllViewModel({
            Email: response.data.Email,
            Status: response.data.Status,
            AccountId: response.data.AccountId,
            HasExpired: response.data.HasExpired,
            IsAdminUser: response.data.IsAdminUser,
            UserRole: response.data.UserRole,
            FirstName: "",
            LastName: "",
            Password: "",
            ConfirmPassword: "",
          });
        });
      }
    }
  }, [invitationId]);

  useEffect(() => {
    let url = "";

    if (
      !invitationId ||
      (viewModel.Status && viewModel.Status !== Enums.InvitationStatus.Pending)
    ) {
      url = "InvalidInvitation";
    } else if (viewModel.HasExpired) {
      url = "ExpiredInvitation";
    }

    if (url) {
      props.history.push(url);
    }
  }, [viewModel, props.history]);

  return (
    <Page centered showBg className="align-items-center">
      <Card>
        <Card.Body>
          <Card.Title className="fw-bold text-center pb-1">
            {t("Title")}
          </Card.Title>
          <Card.Text>
            <TextInput
              className="pt-3"
              label={t("Shared:Email")}
              id="Email"
              name="Email"
              value={viewModel.Email}
              onChange={updateViewModel}
              disabled
            />
          </Card.Text>
          <Card.Text>
            <TextInput
              label={t("Shared:Name")}
              id="FirstName"
              name="FirstName"
              value={viewModel.FirstName}
              onChange={updateViewModel}
              validationModel={validationModel}
              disabled={btnLoading}
            />
          </Card.Text>
          <Card.Text>
            <TextInput
              label={t("Shared:LastName")}
              id="LastName"
              name="LastName"
              value={viewModel.LastName}
              onChange={updateViewModel}
              validationModel={validationModel}
              disabled={btnLoading}
            />
          </Card.Text>
          <Card.Text>
            <PasswordInput
              label={t("Shared:Password")}
              id="Password"
              name="Password"
              value={viewModel.Password}
              onChange={updateViewModel}
              disabled={btnLoading}
            />
            <div className="row pt-2 fs-small">
              <div className="col">
                <ul className="ps-3">
                  <li>{t("PasswordRule1")}</li>
                  <li>{t("PasswordRule2")}</li>
                  <li>{t("PasswordRule3")}</li>
                </ul>
              </div>
              <div className="col">
                <ul className="ps-3">
                  <li>{t("PasswordRule4")}</li>
                  <li>{t("PasswordRule5")}</li>
                </ul>
              </div>
            </div>
          </Card.Text>
          <Card.Text>
            <PasswordInput
              label={t("ConfirmPassword")}
              id="ConfirmPassword"
              name="ConfirmPassword"
              value={viewModel.ConfirmPassword}
              onChange={updateViewModel}
              validationModel={validationModel}
              onEnter={handleSubmit}
              disabled={btnLoading}
            />
          </Card.Text>
          <div className="pt-3">
            <Button
              id="buttonId"
              type="primary"
              btnBlock="true"
              loading={btnLoading}
              onClick={handleSubmit}
            >
              {t("CreateAccount")}
            </Button>
          </div>
          <div className="pt-3">
            {t("AlreadyHaveAnAccount")}
            <AnchorLink
              type="secondary"
              onClick={handleLogin}
              disabled={btnLoading}
            >
              {t("Shared:LogIn")}
            </AnchorLink>
          </div>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default CreateAccount;
